<template>
  <div>
    <background></background>
    <notification-bar></notification-bar>
    <div class="layout-horizontal layout-inflexible">
      <main-menu class="layout-inflexible" :pick-up.sync="pickUp" :current-tag.sync="currentTag"
                 icon="fas fa-user-friends" title="用户管理">
        <main-menu-item
            title="用户管理"
            icon="fas fa-user-friends"
            tag="questionnaire-setting"
            @click.native="$router.push({name: 'user_list'})"
        ></main-menu-item>
        <main-menu-item
          title="订单管理"
          icon="fas fa-shopping-bag"
          tag="trade-list"
          :new-message="unfinishedSchemeTradeCount"
          @click.native="$router.push({name: 'scheme_trade_list'})"
        >
        </main-menu-item>
        <main-menu-item
            title="用户预约"
            icon="fas fa-user-plus"
            tag="punch-setting"
            :new-message="untreatedAppointmentCount"
            @click.native="$router.push({name: 'appointment_list'})"
        ></main-menu-item>
        <div
            class="divider margin-vertical margin-horizontal"
            style="background-color: white; width: calc(100% - 20px); height: 1px;"
        ></div>
        <main-menu-item
            title="内容管理"
            icon="fas fa-toolbox"
            tag="prescription-template"
            @click.native="$router.replace({name: 'organization_content'})"
        ></main-menu-item>
        <main-menu-item
          title="系统管理"
          icon="fas fa-cog"
          tag="system-setting"
          @click.native="$router.push({name: 'system_setting'})"
          v-if="$isSuperMgr()"
        >
        </main-menu-item>
      </main-menu>
      <div class="layout-flexible font-align-left" v-if="routerVisible">
        <animated-router-view :organization-id="organizationId"></animated-router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Background from "@/components/background/Background";
import NotificationBar from "@/components/notificationbar/NotificationBar";
import MainMenu from "@/components/mainmenu/MainMenu";
import MainMenuItem from "@/components/mainmenu/MainMenuItem";
import AnimatedRouterView from "@/components/animatedrouterview/AnimatedRouterView";
import OrganizationUtils from "@/assets/javascript/organization-utils";
import httpapi from "@/assets/javascript/httpapi";

function doLoadUntreatmentAppointCount(organizationId) {
  this.$reqGet({
    path: '/appointment/untreated/count/get',
    data: {
      organizationId,
    }
  }).then(res=>{
    if(this.organizationId === organizationId) {
      this.untreatedAppointmentCount = res.data;
    }
  });
}

function doLoadUnfinishedSchemeTradeCount(organizationId) {
  this.$reqGet({
    path: '/scheme/trade/unfinished/count',
    data: {
      organizationId,
    }
  }).then(res=>{
    if(this.organizationId === organizationId) {
      this.unfinishedSchemeTradeCount = res.data;
    }
  })
}

export default {
  name: "UserManagement",
  mixins: [httpapi],
  components: {AnimatedRouterView, MainMenuItem, MainMenu, NotificationBar, Background},
  data() {
    return {
      organizationId: null,
      routerVisible: true,
      pickUp: true,
      currentTag: null,

      untreatedAppointmentCount: 0,
      unfinishedSchemeTradeCount: 0,

    }
  },
  methods: {
    handleOrganizationChanged(organization) {
      this.organizationId = organization.id;
      this.routerVisible = false;
      this.$nextTick(() => this.routerVisible = true);
      this.loadUntreatedAppointmentList();
      this.handleTradeNotify();
    },
    loadUntreatedAppointmentList() {
      if(this.organizationId) {
        doLoadUntreatmentAppointCount.bind(this)(this.organizationId);
      }
    },
    handleAppointmentNotified() {
      this.loadUntreatedAppointmentList();
    },
    handleTradeNotify: function () {
      if(this.organizationId) {
        doLoadUnfinishedSchemeTradeCount.bind(this)(this.organizationId);
      }
    }
  },
  mounted() {
    this.handleOrganizationChanged(OrganizationUtils.$getOrganization());
    window.eventBus.$on('organization_changed', this.handleOrganizationChanged);
    window.eventBus.$on('notify:appointment', this.handleAppointmentNotified)
    window.eventBus.$on('notify:trade', this.handleTradeNotify);
  },
  destroyed() {
    window.eventBus.$off('organization_changed', this.handleOrganizationChanged);
    window.eventBus.$off('notify:appointment', this.handleAppointmentNotified);
    window.eventBus.$off('notify:trade', this.handleTradeNotify);
  }
}
</script>

<style scoped>

</style>